.button-lang {
  font-size: 0.625rem;
  font-weight: bold;
  border-radius: 9999px;
  color: var(--clr-purple);
  background-color: #fff;
  border: 1px solid var(--clr-purple);
  width: 35px;
  height: 35px;
  opacity: 0.5;
}

.button-lang.active {
  background-color: var(--clr-purple);
  color: #fff;
  opacity: 1;
}
