.app {
  height: 100%;
}

h1 {
  font-size: 28px;
  font-weight: bold;
}

main {
  min-height: calc(100% - 143px);
}
